import React, { Component } from "react";

import { Grid, Typography } from "@material-ui/core";

// Own components
import LabelLineChart from "../Common/ELineChart/LabelLine";

// Api
import { getLoanByLibrary } from "../../api/loan";

class LibraryLoans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      year: "",
      loansByLibrary: []
    }
    this.getStatisticsLoanByLibrary = this.getStatisticsLoanByLibrary.bind(this)
  }

  componentDidMount(){
    this.getStatisticsLoanByLibrary()
  }

  componentDidUpdate(nextProps, prevState){
    if(prevState.year !== this.state.year) {
      this.getStatisticsLoanByLibrary()
    }
  }

  serializerLoanByLibrary(results) {
    const data = results.map(row => ({
      name: row.library,
      prestamos: row.num * 4 - 3
    }))
    return data
  }

  getQuery() {
    var query = "year=" + this.state.year
    return query
  }

  handleChangeYear = (value) => {
    this.setState({
      year: value
    })
  }

  async getStatisticsLoanByLibrary() {
    const statistics = await getLoanByLibrary(this.getQuery())
    if (statistics) {
      const { data, status } = statistics
      if (status === 200) {
        this.setState({
          loansByLibrary: this.serializerLoanByLibrary(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid container direction="row">
        <Grid item xs={12} md={12} lg={12} style={{ height: 300, marginTop: 45 }}>
          <Typography variant="h5">Préstamos por biblioteca</Typography>
          <LabelLineChart
            data={this.state.loansByLibrary}
            lines={[{ key: 'prestamos', color: '#82ca9d' }]} />
        </Grid>
      </Grid>
    )
  }
}
export default LibraryLoans