import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// own components
import LabelLineChart from "../Common/ELineChart/LabelLine";

// api
import { getLoansLastWeekByLibrary } from "../../api/loan";

// constants
import { WEEK_DAY } from "../../constants";

class LoansCurrentWeekByLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      lines: []
    };
    this.getLastWeekByLibrary = this.getLastWeekByLibrary.bind(this)
  }

  componentDidMount(){
    this.getLastWeekByLibrary()
  }

  serializerLines(results){
    const data = results.map(row => ({
      key: row.library,
      color: row.color
    }))
    return data
  }

  serializerData(results){
    var data = []
    for(var i = 0; i < 7; i++){
      data.push({
        name: WEEK_DAY[parseInt(results[0].data[i].weekday) - 1],
        [results[0].library]: (results[0].data.length > i) ? results[0].data[i].cont : 0,
        [results[1].library]: (results[1].data.length > i) ? results[1].data[i].cont : 0,
        [results[2].library]: (results[2].data.length > i) ? results[2].data[i].cont : 0,
        [results[3].library]: (results[3].data.length > i) ? results[3].data[i].cont : 0,
        [results[4].library]: (results[4].data.length > i) ? results[4].data[i].cont : 0,
        [results[5].library]: (results[5].data.length > i) ? results[5].data[i].cont : 0,
      })
    }
    return data
  }

  async getLastWeekByLibrary(){
    const statistics = await getLoansLastWeekByLibrary()
    if(statistics){
      const { data, status } = statistics
      if(status === 200){
        this.setState({
          data: this.serializerData(data),
          lines: this.serializerLines(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid item xs={12} md={12} lg={12} style={{ height: 450, marginTop: 35, marginBottom: 35 }}>
        <Typography variant="h5">Préstamos última semana por biblioteca</Typography>
        <LabelLineChart data={this.state.data} lines={this.state.lines} />  
      </Grid>
    );
  }
}

export default LoansCurrentWeekByLibrary;