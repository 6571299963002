import React, { Component } from "react";

import { COLOR_1 } from "../../constants";

// own components
import  LabelLineChart from "../Common/ELineChart/LabelLine";

// api
import { getUsersByMonth } from "../../api/user";

class UsersByMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      lines: [],
      year: props.year,
      library: props.library,
      multi: props.multi
    };
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount(){
    this.getStatistics()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.year !== this.props.year){
      this.setState({
        year: nextProps.year
      }, () => this.getStatistics())
    }
    if(nextProps.library !== this.props.library){
      this.setState({
        library: nextProps.library
      }, () => this.getStatistics())
    }
  }

  getQuery(){
    var query = "year=" + this.state.year + "&library=" + this.state.library
    return query
  }

  serializerLines(data){
    const lines = data.map((row, index) => ({
      key: row.nombreBiblioteca,
      color: COLOR_1[index]
    }))
    return lines
  }

  serializerData(data){
    var serializer_data = data.map(row => ({
      canarias: row.canarias * this.state.multi === 0? row.canarias * this.state.multi: row.canarias * this.state.multi  + 1,
      ebibliocat: row.ebibliocat === 0? row.ebibliocat: row.ebibliocat * this.state.multi + 1,
      eliburutegia: row.eliburutegia  * this.state.multi,
      guadalajara: row.guadalajara === 0? row.guadalajara: row.guadalajara * this.state.multi + 2,
      madrid: row.madrid * this.state.multi,
      murcia: row.murcia === 0 ? row.murcia: row.murcia * this.state.multi + 1,
      name: row.name,
      navarra: row.navarra === 0? row.navarra: row.navarra *  this.state.multi + 1,
      torrelodones: row.torrelodones * this.state.multi,
      vigo: row.vigo * this.state.multi
    }))
    return serializer_data
  }

  async getStatistics(){
    const statistics = await getUsersByMonth(this.getQuery())
    if(statistics){
      const { data, status } = statistics
      if(status === 200){
        this.setState({
          data: this.serializerData(data.data),
          lines: this.serializerLines(data.librarys)
        })
      }
    }
  }

  render() {
    return (
      <LabelLineChart data={this.state.data} lines={this.state.lines} />
    );
  }
}

export default UsersByMonth;