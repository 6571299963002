import React, { Component } from "react";
import {
  Grid,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// own component
import InvestorGeneralStatistics from "../components/General/InvestorGeneralStatistics";
import LoanTwoLastWeeks from "../components/Loan/LoanTwoLastWeeks";
import LibraryLoans from "../components/Library/LibraryLoans";
import UserByLibrary from "../components/User/UserByLibrary";
import UserLoansInvestor from "../components/Week/UserLoansInvestor";
import UsersByMonth from "../components/User/UsersByMonth";
import LoanByMonthLibraryInvestor from "../components/Loan/LoanByMonthLibraryInvestor";
import LibraryTable from "../components/Library/ELibraryTable";
import YearGeneral from "../components/Year/YearGeneral";
import YearFilter from "../components/Common/EFilter/years";
import LibraryFilter from "../components/Common/EFilter/librarys";
import Loans365DaysInversor from '../components/Loan/Loans365DaysInversor';


const styles = theme => ({
  root: {
    padding: 20,
  },
  metrics: {
    display: 'flex',
    paddingTop: 10,
    justifyContent: 'space-between'

  },
  marginTopMedium: {
    marginTop: 25
  },
  chart: {
    paddingTop: 10,
    width: '70%'
  }
})

class HomeInvestorView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      year: "",
      library: ""
    }
  }

  handleChangeYear = (value) => {
    this.setState({
      year: value
    })
  }
  
  handleChangeLibrary = (value) => {
    this.setState({
      library: value
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Grid className={classes.root} container direction="row">
        <Grid item xs={12}>
          <Typography variant="h5">Resumen</Typography>
          <InvestorGeneralStatistics />
        </Grid>
        <Grid item xs={12} md={6}>
          <UserByLibrary multi={4} />
        </Grid>
        <Grid item xs={12} md={6}>
          <LibraryLoans />
        </Grid>
        <Grid item xs={12}>          
          <Loans365DaysInversor />
        </Grid>
        <Grid item xs={12} className={classes.marginTopMedium}>
          <Typography variant="h4">Datos por biblioteca</Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 300, marginTop: 35, marginBottom: 35 }}>
          <UserLoansInvestor multi={4} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{  marginTop: 35, marginBottom: 35 }}>
          <LibraryTable multi={4} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ marginTop: 35, marginBottom: 35 }}>
          <YearGeneral multi={4} />
        </Grid>
        <Grid item xs={12} >
          <YearFilter onChange={this.handleChangeYear} />
          <LibraryFilter onChange={this.handleChangeLibrary} />
        </Grid>
        <Grid item xs={12} md={12}>
          <LoanTwoLastWeeks multi={4} library={this.state.library} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 700, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Alta de usuarios por mes</Typography>
          <UsersByMonth year={this.state.year} library={this.state.library} multi={4} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 700, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Préstamos por mes</Typography>
          <LoanByMonthLibraryInvestor year={this.state.year} library={this.state.library} multi={4} />
        </Grid>
      </Grid>
    )
  } 
}

export default withStyles(styles)(HomeInvestorView)