import api from "./api";

URL = '/api/v1/loan-statistics/'

async function getEndpoint(url) {
  try {
    const { status, data } = await api.get(url)
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getLoansByMonthLibrary(query) {
  return getEndpoint(URL + "by_month_library/?" + query)
}

export async function getAverageLoanByMonth(query) {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/average_by_month/?' + query)
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getYears(query) {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/years/?' + query)
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getLoanByMonth(query) {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/by_month/?' + query)
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getLoanByLibrary(query) {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/by_library/?' + query)
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getGeneralLoans() {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/general/')
    if(status === 200){
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getLoansTwoLastWeeks(query) {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/two_last_weeks/?' + query)
    if(status === 200){
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getLoansLastWeekByLibrary() {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/last_week_by_library/')
    if(status === 200){
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getLoans365Days() {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/loans-365-days/')
    if( status === 200 ) {
      const response = { status: status, data: data}
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getLoansByDay(params) {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/loans-by-days/', { params })
    if( status === 200 ) {
      const response = { status: status, data: data}
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getExpensive365Days() {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/expensive-365-days/')
    if( status === 200 ) {
      const response = { status: status, data: data}
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getUsersRegisterByDay(params) {
  try {
    if(!params['year'] || !params['month']){
      params['year'] = 2020
      params['month'] = 1
    }
    const { status, data } = await api.get('/api/v1/user-statistics/register-by-day/', { params })
    if( status === 200 ) {
      const response = { status: status, data: data}
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getExpensesByDay(params) {
  try {
    const { status, data } = await api.get('/api/v1/loan-statistics/expenses-by-day', { params })
    if( status === 200 ) {
      const response = { status: status, data: data}
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}