import api from "./api";

export async function getGeneralStatistics() {
  try {
    const { status, data } = await api.get('/api/v1/general-statistics/system/')
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    console.log("error", error)
    const response = { status: error.request.status }
    return response
  }
}