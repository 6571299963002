import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import UserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import LoansIcon from "@material-ui/icons/Inbox";
import ExpensiveIcon from "@material-ui/icons/AttachMoney";

// own components
import SimpleCard from "../Common/ECard/SimpleCard";

// api
import { getGeneral } from "../../api/user";

const styles = {
  paddingGird: {
    padding: 5
  },
  icon: {
    fontSize: 50
  }
}

class AverageCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: props.year,
      library: props.library,
      data: []
    };
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount(){
    this.getStatistics()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.library !== this.state.library || nextProps.year !== this.state.year){
      this.setState({
        year: nextProps.year,
        library: nextProps.library
      }, () => this.getStatistics())
    }
  }

  getQuery(){
    return 'year=' + this.state.year + '&library=' + this.state.library
  }

  serializerData(results){
    const { classes } = this.props
    const data = [
      {label: 'Nº de usuarios', icon: <UserCircleIcon className={classes.icon} />, value: results.total_users},
      {label: 'Media de préstamos', icon: <LoansIcon className={classes.icon} />, value: results.average_loans},
      {label: 'Media de gasto', icon: <ExpensiveIcon className={classes.icon} />, value: results.average_expenditure}
    ]
    return data
  }

  async getStatistics(){
    const statistics = await getGeneral(this.getQuery())
    if(statistics){
      const { data, status } = statistics
      if(status === 200){
        this.setState({
          data: this.serializerData(data)
        })
      }
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row">
        {
          this.state.data.map((row, index) => (
            <Grid item xs={12} lg={4} md={4} sm={6} key={index} className={classes.paddingGird} >
              <SimpleCard 
              title={row.label}
              icon={row.icon}
              value={row.value} />
            </Grid>
          ))
        }
      </Grid>        
    );
  }
}

export default withStyles(styles)(AverageCard);