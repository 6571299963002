import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

// own components
import ETable from "../Common/ETable/ETable";

// apis 
import { getStatisticsLibrary } from "../../api/library";

class ELibraryTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      multi: props.multi,
      columnName: [
        { id: 'library', numeric: false, disablePadding: false, label: 'Biblioteca' },
        { id: 'num_loans', numeric: true, disablePadding: false, label: 'Nº préstamos' },
        { id: 'daily_average', numeric: false, disablePadding: false, label: 'M. diaria' },
        { id: 'average_last_month', numeric: false, disablePadding: false, label: 'M. último mes' },
        { id: 'num_users', numeric: true, disablePadding: false, label: 'Nº Usuarios' },
        { id: 'budget', numeric: true, disablePadding: false, label: 'Presupesto total' },
        { id: 'expenditure', numeric: true, disablePadding: false, label: 'Gasto' },
        { id: 'remaining_budget', numeric: true, disablePadding: false, label: 'Presupuesto restante' },
        { id: 'remaining_days', numeric: true, disablePadding: false, label: 'Dias Restantes'},
        { id: 'average_loan_by_users', numeric: true, disablePadding: false, label: 'M. préstamo por usuario' },
        { id: 'average_expenditure_by_users', numeric: true, disablePadding: false, label: 'M. ingreso por usuario' }
      ],
      data: [],
      loans: []
    }
    this.getLibrarys = this.getLibrarys.bind(this)
  }

  serializerData(results) {
    // los nombres de cada dato debe ser igual al de los ids de las columnas
    const data = results.map(row => (
      {
        id: row.pk, 
        library: row.nombreBiblioteca, 
        num_loans: row.loans * this.state.multi, 
        daily_average: row.daily_average * this.state.multi,
        average_last_month: row.average_last_month * this.state.multi,
        num_users: row.nusers_acepted * this.state.multi,
        budget: row.budget * this.state.multi,
        expenditure: row.expenditure * this.state.multi,
        remaining_budget: row.remaining_budget,
        remaining_days: row.remaining_days * this.state.multi,
        average_loan_by_users: row.average_loan_by_users * this.state.multi,
        average_expenditure_by_users: row.average_expenditure_by_users * this.state.multi
      }
    ));
    return data
  }

  serializerLoansLibrary(results) {
    const data = results.map(row => (
      {
        name: row.nombreBiblioteca, 
        prestamos: row.loans,
        gasto: row.expenditure
      }
    ));
    return data
  }


  componentDidMount() {
    this.getLibrarys()
  }

  async getLibrarys() {
    try {
      const librarys = await getStatisticsLibrary()
      if (librarys) {
        const { status, data } = librarys
        if (status === 200) {
          this.setState({
            data: this.serializerData(data)
          })
          
        }
      }
    } catch (error) {

    }
  }
 
  render() {
    return (
      
      <Grid direction="row" container >
        <ETable
          columnName={this.state.columnName}
          data={this.state.data}
          orderBy="library"
          titleTable="Estadisticas por biblioteca" />        
      </Grid>
    );
  }
}

export default ELibraryTable;