import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'

const styles = theme => ({
  root: {
    width: '50%'
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class EHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };
  }



  handleProfileMenuOpen = event => {
    console.log(Boolean(event.currentTarget))
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleCloseSession = () => {
    this.props.signOff()
    this.props.notIsInvestor()
    this.props.history.push('/login')
  }

  handleDrawerOpen = () => {
    if (this.props.isOpenDrawer) {
      this.props.onCloseDrawer()
    } else {
      this.props.onOpenDrawer()
    }
  }

  handleSearchChange = (event) => {
    this.props.onWriteSearch(event.target.value)
  }

  renderMenu = () => (
    <Menu
      anchorEl={this.state.anchorEl}
      open={Boolean(this.state.anchorEl)}
      onClose={this.handleMenuClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={this.handleMenuClose}>Mi cuenta</MenuItem>
      <MenuItem onClick={this.handleCloseSession}>Cerrar sesión</MenuItem>
    </Menu>
  )

  render() {
    const { anchorEl } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    return (
      <div className={classes.root}>
        <AppBar position="fixed">
          <Toolbar className={classes.menuBar}>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              Estadisticas Efilm
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                <MoreIcon />
              </IconButton>
            </div>
            <div>{this.renderMenu()}</div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

EHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isOpenDrawer: state.drawerReducer.isOpenDrawer,
    textSearch: state.headerReducer.textSearch
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signOff: () => dispatch({ type: 'NOT_AUTHENTICATED' }),
    notIsInvestor: () => dispatch({ type: 'NOT_IS_INVESTOR'}),
    onOpenDrawer: () => dispatch({ type: 'OPEN_DRAWER' }),
    onCloseDrawer: () => dispatch({ type: 'NOT_OPEN_DRAWER' }),
    onWriteSearch: (state) => dispatch({textSearch: state, type: 'WRITE_SEARCH' }),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(EHeader)));