import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Header from '../components/Common/EHeader/EHeader'

const styles = theme => ({
    container: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        height: "calc(100vh)"
    },
    header: {
        width: '100%',
        height: '10%'
    },
    drawer: {
        width: '19%',
        height: '90%',
    },
    content: {
        width: '95%',
        alignSelf: 'center',
        height: '90%',
    }

})


const HomeInvestorLayout  =  (props) => {
    const { classes } = props;
    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <Header />
            </div>
            <div className={classes.content}>
                {props.children}
            </div>
        </div>

    )
}

export default withStyles(styles)(HomeInvestorLayout);