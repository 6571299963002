import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// own components
import GeneralStatisticsSupplier from "../components/Supplier/GeneralStatisticsSupplier";
import GeneralStatisticsColor from "../components/Color/GeneralStatisticsColor";
import TableGenre from "../components/Genre/TableGenre";
import AgeChart from "../components/Age/AgeChart";
import GeneralStatistics from "../components/General/GeneralStatistics";

const styles = theme => ({
  root: {
    padding: 20
  },
  metrics: {
    display: 'flex',
    paddingTop: 10,
    justifyContent: 'space-between'

  },
  chart: {
    paddingTop: 10,
    width: '70%'

  }
})


class HomeView extends Component {
  
  componentDidMount() {
    document.title = "eFilm | Dashboard"
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h5">Estadisticas generales</Typography>
        </Grid>
        <Grid item xs={12}>
          <GeneralStatistics />
        </Grid>
        <Grid item xs={12} style={{marginTop: 45}}>
          <Typography variant="h4">Estadisticas por proveedor</Typography>
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <GeneralStatisticsSupplier />
        </Grid>
        <Grid item xs={12} style={{marginTop: 120}}>
          <Typography variant="h4">Estadisticas por color</Typography>
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <GeneralStatisticsColor />
        </Grid>
        <Grid item xs={12} style={{marginTop: 120}}>
          <Typography variant="h4">Estadisticas por género</Typography>
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <TableGenre />
        </Grid>
        <Grid item xs={12} style={{marginTop: 120}}>
          <Typography variant="h4">Préstamos por edad</Typography>
        </Grid>
        <Grid item xs={12} style={{marginTop: 15}}>
          <AgeChart />
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(HomeView);