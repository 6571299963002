import React, { Component } from "react";

// own components 
import ESelect from "../../Common/ESelect/ESelect";

// api 
import { getYears } from "../../../api/loan";

class years extends Component {
  constructor(props) {
    super(props);
    this.state = {
      library: null,
      options: []
    };
    this.getYearsList = this.getYearsList.bind(this)
  }

  componentDidMount(){
    this.getYearsList()
  }

  serializerYears(years){
    const data = years.map(row => ({
      value: row.year,
      text: row.year
    }))
    data.push({
      value: "12-months",
      text: "Últimos 12 meses"
    })
    return data
  }

  async getYearsList(library){
    var query = ""
    if(library){
      query = "library=" + library
    }
    const response = await getYears(query)
    if (response){
      const { status, data } = response
      if (status === 200 ){
        this.setState({
          options: this.serializerYears(data)
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.library !== this.props.library){
      this.getYearsList(nextProps.library)
    }
  }

  handleChangeSelect = (value) => {
    this.props.onChange(value)
  }

  render() {
    return (
      <ESelect 
      options={this.state.options}
      name="Año"         
      id="select-year"
      onChange={this.handleChangeSelect} />
    );
  }
}

export default years;