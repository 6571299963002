import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

// own components
import LibraryTable from "./ELibraryTable";
import YearComponent from "../Year/YearComponent";
import UsersByMonth from "../User/UsersByMonth";
import LibraryFilter from "../Common/EFilter/librarys";
import LoanByMonthLibrary from "../Loan/LoanByMonthLibrary";
import YearFilter from "../Common/EFilter/years";
import UsersLoansByWeeks from "../Week/UsersLoans";

class LibraryComponent extends Component {

  constructor(props){
    super(props)
    this.state = {
      library: "",
      year: ""
    }
  }

  handleChangeLibrary = (value) => {
    this.setState({
      library: value
    })
  }

  handleChangeYear = (value) => {
    this.setState({
      year: value
    })
  }

  render() {
    return (
      <Grid container direction="row">
        <Typography variant="h5">Estadisticas por semanas</Typography>
        <Grid item xs={12} md={12} lg={12} style={{ height: 300, marginTop: 35, marginBottom: 35 }}>
          <UsersLoansByWeeks />
        </Grid>
        <Grid item xs={12}>
          <LibraryTable  multi={1} />
        </Grid>
        <Grid item xs={12}>
          <YearComponent/>
        </Grid>
        <Grid container direction="row">
          <LibraryFilter onChange={this.handleChangeLibrary} />
          <YearFilter onChange={this.handleChangeYear} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 700, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Alta de usuarios por mes</Typography>
          <UsersByMonth year={this.state.year} library="" multi={1} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 700, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Préstamos por mes</Typography>
          <LoanByMonthLibrary year={this.state.year} multi={1} library={this.state.library} />
        </Grid>
      </Grid>
    );
  }
}

export default LibraryComponent;