import React, { Component } from "react";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

class CustomizedLabel extends Component {
  state = {}
  render() {
    const { x, y, stroke, value } = this.props;
    return <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">{value}</text>

  }
}
class CustomizedAxisTick extends Component {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
      </g>
    );
  }
}

class LabelLine extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: props.data,
      lines: props.lines
    }
  }

  componentWillReceiveProps(prevProps) {
    this.setState({
      data: prevProps.data,
      lines: prevProps.lines
    })
  }

  render() {
    return (
      <ResponsiveContainer>
        <LineChart width={600} height={300} data={this.state.data}
          margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" height={60} tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip />
          <Legend />
          {
            this.state.lines.map((row, index) => {
              return (
                <Line 
                  type="monotone" 
                  key={index} 
                  dataKey={row.key} 
                  stroke={row.color} 
                  
                />
              )
            })
          }

        </LineChart>
      </ResponsiveContainer>
    );
  }
};

export default LabelLine;