import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { getStatisticsByYear, getStatisticsByMonth } from "../api/library";

const styles = theme => ({
  root: {
    padding: 20
  },
  metrics: {
    display: 'flex',
    paddingTop: 10,
    justifyContent: 'space-between'

  },
  chart: {
    paddingTop: 10,
    width: '70%'

  }
})

class HomeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataStatisticsByYear: [],
      dataStatisticsByMonth: []
    };
    this.getDataByYear = this.getDataByYear.bind(this)
    this.getDataByMonth = this.getDataByMonth.bind(this)
  }

  componentDidMount() {
    this.getDataByYear()
    this.getDataByMonth()
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.getDataByYear()
      this.getDataByMonth()
    }
  }

  dataSerializerByYear(results) {
    const data = results.map(row => ({
      name: row.year,
      prestamos: row.num
    }))    
    return data
  }

  dataSerializerByMonth(results) {
    const data = results.map(row => ({
      name: row.month,
      prestamos: row.num
    }))
    return data
  }

  async getDataByYear() {
    const statistics = await getStatisticsByYear(this.props.match.params.nombre)
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          dataStatisticsByYear: this.dataSerializerByYear(data)
        })
      }
    }
  }

  async getDataByMonth() {
    var current_year = (new Date()).getFullYear()
    const statistics = await getStatisticsByMonth(this.props.match.params.nombre, current_year)
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          dataStatisticsByMonth: this.dataSerializerByMonth(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid container>
        <Grid item lg={12}>
          <Card elevation={1}>
            <CardContent>
              <Grid container>
                <Grid item lg={4}>
                  <Typography variant="h5">Préstamos Anuales</Typography>
                </Grid>                
              </Grid>              
            </CardContent>
            <CardContent style={{paddingBottom:0}}>
              <BarChart 
                width={600} height={300} data={this.state.dataStatisticsByYear}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
              >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend />
                <Bar dataKey="prestamos" fill="#8884d8" />                
              </BarChart>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12}>
          <Card elevation={1}>
            <CardContent>
              <Grid container>
                <Grid item lg={4}>
                  <Typography variant="h5">Préstamos Mensuales</Typography>
                </Grid>                
              </Grid>              
            </CardContent>
            <CardContent style={{paddingBottom:0}}>
              <BarChart 
                width={600} height={300} data={this.state.dataStatisticsByMonth}
                margin={{top: 5, right: 30, left: 20, bottom: 5}}
              >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip/>
                <Legend />
                <Bar dataKey="prestamos" fill="#8884d8" />                
              </BarChart>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(HomeView);