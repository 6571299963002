import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

// own components
import StatisticsLoan from "../components/Loan/StatisticsLoan";

const styles = theme => ({
  root: {
    padding: 20
  }
})
class LoanView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      year: props.year
    }
  }

  componentWillReceiveProps(prevProps){
    this.setState({
      year: prevProps.year
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row" className={classes.root}>
        <StatisticsLoan />
      </Grid>
    )
  }
}

export default withStyles(styles)(LoanView)