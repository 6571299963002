import { WRITE_SEARCH } from '../constants' 


const initialState = {
    textSearch: "",
}

const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case WRITE_SEARCH:
            return {
                ...state,
                textSearch: action.textSearch
            }
        default:
            return state;
    }
}
export default headerReducer;