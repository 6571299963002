import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

// api 
import { getAverageOfLoans, getAverageExpenditure, getAverageUserRegister } from "../../api/average";

// own components 
import BiaxialLine from "../Common/ELineChart/BiaxialLine";

class AverageStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.getStatisticsAverageUserRegister = this.getStatisticsAverageUserRegister.bind(this)
    this.getStatisticsAverageLoans = this.getStatisticsAverageLoans.bind(this)
    this.getStatisticsAverageExpenditure = this.getStatisticsAverageExpenditure.bind(this)    
  }

  componentDidMount() {
    this.getStatisticsAverageUserRegister()
    this.getStatisticsAverageLoans()
    this.getStatisticsAverageExpenditure()
  }

  dataSerializerLoans(results){
    const data = {
      name: 'prestamos', 
      semanal: Number.parseFloat(Number(results.average_weekly_loans).toFixed(2)), 
      mensual: Number.parseFloat(Number(results.average_monthly_loans).toFixed(2)),
      anual: Number.parseFloat(Number(results.average_yearly_loans).toFixed(2))
    }
    return data
  }

  dataSerializerUserRegistration(results){
    const data = {
      name: 'Registros', 
      semanal: Number.parseFloat(Number(results.average_weekly_users).toFixed(2)), 
      mensual: Number.parseFloat(Number(results.average_monthly_users).toFixed(2)),
      anual: Number.parseFloat(Number(results.average_yearly_users).toFixed(2))
    }
    return data
  }

  dataSerializerExpenditure(results){
    const data = {
      name: 'Gasto', 
      semanal: Number.parseFloat(Number(results.average_weekly_expenditure).toFixed(2)), 
      mensual: Number.parseFloat(Number(results.average_monthly_expenditure).toFixed(2)),
      anual: Number.parseFloat(Number(results.average_yearly_expenditure).toFixed(2))
    }
    return data
  }

  async getStatisticsAverageUserRegister(){
    const statistics = await getAverageUserRegister()
    if (statistics){
      const {status, data} = statistics
      if(status === 200){
        var serialized = this.dataSerializerUserRegistration(data)
        this.setState({
          data: [...this.state.data, serialized]
        })
      }
    }
  }

  async getStatisticsAverageLoans(){
    const statistics = await getAverageOfLoans()
    if (statistics){
      const {status, data} = statistics
      if(status === 200){
        var serialized = this.dataSerializerLoans(data)
        this.setState({
          data: [...this.state.data, serialized]
        })
      }
    }
  }

  async getStatisticsAverageExpenditure(){
    const statistics = await getAverageExpenditure()
    if (statistics){
      const {status, data} = statistics
      if(status === 200){
        var serialized = this.dataSerializerExpenditure(data)
        this.setState({
          data: [...this.state.data, serialized]
        })
      }
    }
  }

  render() {
    return (
      <Grid direction="row" container>
        <Grid item lg={12} md={12} sm={12} style={{ height: 300, paddingTop: 40 }}>
          <BiaxialLine
            data={this.state.data}
            linesLeft={
              [
                {key: "semanal", color: "#0088FE"}, 
                {key: "mensual", color: "#00C49F"}
              ]
            }
            linesRight={ [{key: "anual", color: "#FFBB28"}] }
          />
        </Grid>
      </Grid>
    );
  }
}

export default AverageStatistics;