import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

// own component
import YearGeneral from "./YearGeneral";

class YearComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Grid item xs={12}>
        <Grid item xs={12} md={12}>
          <YearGeneral  multi={1} />
        </Grid>
      </Grid>          
    );
  }
}

export default YearComponent;