import React, { Component } from "react";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2
  }
})

class ESelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      name: props.name,
      id: props.id
    };
  }

  componentWillReceiveProps(prevProps){
    this.setState({
      options: prevProps.options,
      value: prevProps.value,
    })
  }

  handleChange = (event) => {
    this.props.onChange(event.target.value)
  }

  render() {
    const { classes } = this.props
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={this.setState.id}>{this.state.name}</InputLabel>
        <Select 
        native
        onChange={(event) => this.handleChange(event)}
        inputProps={{
          name: this.state.name,
          id: this.state.id
        }}
        >
        <option value=""></option>
        {
          this.state.options.map(row => {
            return (<option key={row.value} value={row.value}>{row.text}</option>)
          }) 
        }
        </Select>
      </FormControl>
    );
  }
}

export default withStyles(styles)(ESelect);