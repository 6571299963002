import axios from 'axios'
import {BASE_URL} from '../constants';

const  headers = {
    'Content-Type': 'application/json'
}

const api = {
    handleApi() {
        const client = axios.create({
            baseURL: BASE_URL,
            headers: headers
        });
        client.interceptors.request.use(config => {
            const token = localStorage.getItem('token')
            if (token) {
                config.headers.Authorization = `JWT ${token}`
            }
            return config
        }, (error) => { 
            return Promise.reject(error)})
        return client
    }
}
export default api.handleApi();