import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// own components
import PorcentageLoansByProvider from "../components/Supplier/LoansByProvider";
import FilterYear from "../components/Common/EFilter/years";
import FilterLibrary from "../components/Common/EFilter/librarys";

const styles = theme => ({
  root: {
    padding: 20
  }
})

class ProviderView extends Component {
  constructor(props){
    super(props)
    this.state = {
      library: "",
      year: ""
    }
  }

  handleLibrary = (value) => {
    this.setState({
      library: value
    })
  }

  handleYear = (value) => {
    this.setState({
      year: value
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.root} container>
        <Grid item md={12}>
          <FilterYear onChange={this.handleYear} /> <FilterLibrary onChange={this.handleLibrary} />
        </Grid>
        <Grid item md={6}>
          <PorcentageLoansByProvider library={this.state.library} year={this.state.year} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProviderView);