//validators


export default function validateLogin({username, password}) {
    var validateLogin = null
    if (username.length > 0 && password.length > 0) {
        validateLogin = true
    } else {
        validateLogin = false
    }
    return validateLogin
}
