import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

// own components
import GeneralAverage from "../components/Average/AverageStatistics";

const styles = theme => ({
  root: {
    padding: 20
  }
})

class AverageView extends Component {
  render(){
    const { classes } = this.props
    return(
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h4">Medias Generales</Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}> 
          <GeneralAverage />
        </Grid>
        <Grid item xs={12} md={6} lg={6}> 
        </Grid>
      </Grid>
    )
  } 
}

export default withStyles(styles)(AverageView)