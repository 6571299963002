import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MoneyIcon from "@material-ui/icons/MonetizationOn";
import MovieIcon from "@material-ui/icons/Theaters";
import CodeIcon from "@material-ui/icons/Code";
import UserAceptedIcon from "@material-ui/icons/VerifiedUser";
import PendingUserIcon from "@material-ui/icons/NotificationImportant";
import RejectedUserIcon from "@material-ui/icons/NotInterested";
import FictitiousUserIcon from "@material-ui/icons/Adb";
import LoansIcon from "@material-ui/icons/TrendingUp";
import AverageLoansIcon from "@material-ui/icons/BarChart";
import AverageExpenditureIcon from "@material-ui/icons/InsertChartOutlined";

// own components
import SimpleCard from "../Common/ECard/SimpleCard";

// api
import { getGeneralStatistics } from "../../api/general";
import { getAverageOfUser } from "../../api/average";

const styles = {
  paddingGird: {
    padding: 5
  },
  icon: {
    fontSize: 50
  }
}

class GeneralStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      average_of_user: []
    };
    this.getStatistics = this.getStatistics.bind(this)
    this.getAverageOfUserStatistics = this.getAverageOfUserStatistics.bind(this)
  }

  componentDidMount() {
    this.getStatistics()
    this.getAverageOfUserStatistics()
  }

  dataSerializer(data) {
    const { classes } = this.props
    const results = [
      { label: "Préstamos", icon: <LoansIcon className={classes.icon} />, value: data.loans },
      { label: "Ingresos", icon: <MoneyIcon className={classes.icon} />, value: data.expenditure + " €" },
      { label: "Audiovisuales", icon: <MovieIcon className={classes.icon} />, value: data.naudiovisual },
      { label: "Códigos Filmin", icon: <CodeIcon className={classes.icon} />, value: data.nfilmin_codes },
      { label: "Usuarios ficticios", icon: <FictitiousUserIcon className={classes.icon} />, value: data.nfictitious_users },
      { label: "Usuarios aceptados", icon: <UserAceptedIcon className={classes.icon} />, value: data.nusers_acepted },
      { label: "Usuarios pendientes", icon: <PendingUserIcon className={classes.icon} />, value: data.npending_users },
      { label: "Usuarios rechazados", icon: <RejectedUserIcon className={classes.icon} />, value: data.nrejected_users }

    ]
    return results
  }

  dataSerializerAverageOfUser(results){
    const { classes } = this.props
    const data = [
      { label: "Media préstamo por usuario", icon: <AverageLoansIcon className={classes.icon} />, value: Number(results.average_of_loans).toFixed(2) },
      { label: "Media ingresos por usuario", icon: <AverageExpenditureIcon className={classes.icon} />, value: Number(results.expenditure_average).toFixed(2) + " €" }
    ]
    return data
  }

  async getStatistics() {
    const statistics = await getGeneralStatistics()
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          data: this.dataSerializer(data)
        })
      }
    }
  }

  async getAverageOfUserStatistics() {
    const statistics = await getAverageOfUser()
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          average_of_user: this.dataSerializerAverageOfUser(data)
        })
      }
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Grid direction="row" container >
        {this.state.data.map((row, index) => (
          <Grid item key={index} lg={3} md={4} sm={6} xs={6} className={classes.paddingGird}>
            <SimpleCard
              title={row.label}
              icon={row.icon}
              value={row.value}
            />
          </Grid>
        ))}
        {this.state.average_of_user.map((row, index) => (
          <Grid item key={index} lg={6} md={6} sm={6} xs={6} className={classes.paddingGird}>
            <SimpleCard
              title={row.label}
              icon={row.icon}
              value={row.value}
            />
          </Grid>
        ))}
        
      </Grid>
    );
  }
}

export default withStyles(styles)(GeneralStatistics);