import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

const snackBarStatus = ({status, open, closeOn, message }) => {
    if (status === 400) {
        return (
            <Snackbar
                message={"Error enviando los datos"}
                open={open}
                autoHideDuration={4000}
                onClose={closeOn}/>
        )}
    if (status === 500 || status === 0) {
        return (
            <Snackbar
                message={"Error en el servidor, intentar mas tarde"}
                open={open}
                autoHideDuration={4000}
                onClose={closeOn}/>
        )}
    if (status === 201) {
        return (
            <Snackbar
                message={"Se creó exitosamente"}
                open={open}
                autoHideDuration={4000}
                onClose={closeOn}/>
        )}
    if (status === 200) {
        return (
            <Snackbar
                message={message}
                open={open}
                autoHideDuration={4000}
                onClose={closeOn}/>
        )}
    return( <div></div>)   
}

export default snackBarStatus;