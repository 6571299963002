import React, { Component } from 'react'
import LoginComponent from '../components/ELogin/ELogin'

export default class LoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  
  render() {
    return (
      <div>
        <LoginComponent
          history={this.props.history}
        />
      </div>
    )
  }
}
