import api from "./api";

export async function getAverageOfUser() {
  try {
    const { status, data } = await api.get('/api/v1/average-statistics/of_user/')
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getAverageOfLoans() {
  try {
    const { status, data } = await api.get('/api/v1/average-statistics/of_loans/')
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getAverageUserRegister() {
  try {
    const { status, data } = await api.get('/api/v1/average-statistics/user_registration/')
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getAverageExpenditure() {
  try {
    const { status, data } = await api.get('/api/v1/average-statistics/expenditure/')
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}