import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import auth from '../../api/login'
import SnackBarStatus from '../Common/ESnackBar/ESnackbar'
import validateLogin from '../../validators/validators'
import { connect } from "react-redux";


//class styles

const styles = theme => ({
  container: {
    display: 'flex',
  },
  paper: {
    height: 385
  },
  textField: {
    width: "90%",
    marginLeft: 20,
    marginRight: 2,
    marginTop: 30
  },
  loginIcon: {
    fontSize: 50  
  },
  button: {
    paddingTop: 5
  }
})


class ELogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: {
        username: '',
        password: ''
      },
      showPassword: false,
      status: null,
      onSnackbar: false,
      errorValidate: false,
      helperText: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const payloadCopy = {...this.state.payload}
    const key = event.target.name
    payloadCopy[key] = event.target.value
    this.setState({payload: payloadCopy})
  }

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  async handleSubmit (event) {
    try {
      event.preventDefault();
      const validate = validateLogin(this.state.payload)
      if (!validate) {
        this.setState({errorValidate: true, helperText: 'Los campos son requeridos'})
      } else {
          const query = this.state.payload
          const response = await auth(query);
          const {token, status, isInvestor} =  response
          if (token) {
            this.setState({errorValidate: false, helperText: ''})
            localStorage.setItem('token', token)
            this.props.onAuthUser()
            if(isInvestor){
              this.props.onIsInvestor()
            }            
            this.props.history.push('/home')
          }
          if (status === 400) {
            this.setState({status: status, onSnackbar: true})
          } else if (status === 500 || status === 0) {
            this.setState({status: status, onSnackbar: true})
          } else {
            this.setState({errorValidate: false, helperText: ''})
          }
      }
    } catch (error) {
      console.log(error)
    }
  }
  handleCloseSnackBar = () => (this.setState({onSnackbar:false}))

  renderSnackBar = () => {
    return (
      <SnackBarStatus
        status={this.state.status}
        open={this.state.onSnackbar}
        closeOn={this.handleCloseSnackBar}
      />
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid direction="column" container className={classes.root}>
        <Grid item xs={12} lg={12}>
          <Grid 
            container
            direction="column"
            justify="center"
            alignItems="center"
            alignContent="center"
            style={{paddingTop:80}}
          >
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <Grid container direction="column" alignItems="center">
                  <Icon style={{fontSize: 40, textAlign:"center", marginTop: 15}} className={classNames(classes.icon, 'fas fa-user')}  color="secondary"/>
                  <Typography variant="h6" style={{marginTop: 5, color: "rgba(0, 0, 0, 0.54)"}}>
                    Ingresar
                  </Typography>
                </Grid>
                <form>
                  <TextField
                    label="Usuario"
                    required
                    id="standard-required"
                    name="username"
                    helperText={this.state.helperText}
                    error={this.state.errorValidate}
                    className={classes.textField}
                    value={this.state.payload.username}
                    onChange={this.handleChange}
                  />
                  <TextField
                    label="Password"
                    name="password"
                    required
                    helperText={this.state.helperText}
                    error={this.state.errorValidate}
                    className={classes.textField}
                    value={this.state.payload.password}
                    onChange={this.handleChange}
                    type={this.state.showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Grid container alignContent="center" alignItems="center" justify="flex-end" style={{marginTop:50, paddingRight:5}}>
                    <Button type="submit" onClick={this.handleSubmit} variant="contained" color="primary" style={{width: "98%"}}>
                      Ingresar
                      <Icon style={{fontSize: 15, marginBottom:5, marginLeft: 5 }} className={classNames(classes.icon, 'fas fa-user')}/>
                    </Button>
                  </Grid>
                </form>
                </Paper>
              </Grid>
          </Grid>
        </Grid>
        <Grid>
          {this.renderSnackBar()}
        </Grid>
      </Grid>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onAuthUser: () => dispatch({type: 'AUTHENTICATED'}),
      onIsInvestor: () => dispatch({type: 'IS_INVESTOR'})
  }
}

export default connect(undefined, mapDispatchToProps)(withStyles(styles)(ELogin));
