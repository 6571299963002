import { AUTHENTICATED , NOT_AUTHENTICATED, IS_INVESTOR, NOT_IS_INVESTOR } from '../constants' 


const initialState = {
    isAuth: false,
    isInvestor: false
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATED:
            return {
                ...state,
                isAuth: true
            }
        case NOT_AUTHENTICATED:
            return {
                ...state,
                isAuth: false
            }
        case IS_INVESTOR:
            return {
                ...state,
                isInvestor: true
            }
        case NOT_IS_INVESTOR:
            return {
                ...state,
                isInvestor: false
            }
        default:
            return state;
    }
}
export default userReducer;