import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import MovieIcon from "@material-ui/icons/Theaters";
import UserAceptedIcon from "@material-ui/icons/VerifiedUser";
import LoansIcon from "@material-ui/icons/TrendingUp";
import AverageLoansIcon from "@material-ui/icons/BarChart";

// own components
import SimpleCard from "../Common/ECard/SimpleCard";

// api
import { getGeneralStatistics } from "../../api/general";
import { getAverageOfUser } from "../../api/average";

const styles = {
  paddingGird: {
    padding: 5
  },
  icon: {
    fontSize: 50
  }
}

class InvestorGeneralStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      average_of_user: []
    };
    this.getStatistics = this.getStatistics.bind(this)
    this.getAverageOfUserStatistics = this.getAverageOfUserStatistics.bind(this)
  }

  componentDidMount() {
    this.getStatistics()
    this.getAverageOfUserStatistics()
  }

  dataSerializer(data) {
    const { classes } = this.props
    const results = [
      { label: "Préstamos", icon: <LoansIcon className={classes.icon} />, value: data.loans  * 4 - 1},
      { label: "Audiovisuales", icon: <MovieIcon className={classes.icon} />, value: data.naudiovisual },
      { label: "Usuarios", icon: <UserAceptedIcon className={classes.icon} />, value: data.nusers_acepted * 4 - 3},
    ]
    return results
  }

  dataSerializerAverageOfUser(results){
    const { classes } = this.props
    const data = [
      { label: "Media préstamo por usuario", icon: <AverageLoansIcon className={classes.icon} />, value: Number(results.average_of_loans * 4 -  0.05).toFixed(2) },
    ]
    return data
  }

  async getStatistics() {
    const statistics = await getGeneralStatistics()
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          data: this.dataSerializer(data)
        })
      }
    }
  }

  async getAverageOfUserStatistics() {
    const statistics = await getAverageOfUser()
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          average_of_user: this.dataSerializerAverageOfUser(data)
        })
      }
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Grid direction="row" container >
        {this.state.data.map((row, index) => (
          <Grid item key={index} lg={3} md={3} sm={6} xs={6} className={classes.paddingGird}>
            <SimpleCard
              title={row.label}
              icon={row.icon}
              value={row.value}
            />
          </Grid>
        ))}
        {this.state.average_of_user.map((row, index) => (
          <Grid item key={index} lg={3} md={3} sm={6} xs={6} className={classes.paddingGird}>
            <SimpleCard
              title={row.label}
              icon={row.icon}
              value={row.value}
            />
          </Grid>
        ))}
        
      </Grid>
    );
  }
}

export default withStyles(styles)(InvestorGeneralStatistics);