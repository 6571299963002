import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";


// own component
import UserByLibrary from "./UserByLibrary";
import NUsersByNLoans from "./NUsersByNLoans";
import UsersAverageByDayMonth from "./UsersAverageByDayMonth";
import AverageCard from "./AverageCard";
import YearFilter from "../Common/EFilter/years";
import LibraryFilter from "../Common/EFilter/librarys";

class UserStatistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      library: "",
      year: ""
    };
  }

  handleChangeLibrary = (value) => {
    this.setState({
      library: value
    })
  }

  handleChangeYear = (value) => {
    this.setState({
      year: value
    })
  }

  componentDidUpdate(nextProp, prevState){
    if(prevState.library !== this.state.library || prevState.year !== this.state.yaer){
      
    }
  }

  render() {
    return (
      <Grid container direction="row">
        <Grid item xs={12} md={6} lg={6}>
          <Typography variant="h5">Usuarios por biblioteca</Typography>
          <UserByLibrary  multi={1} />
        </Grid>
        <Grid container direction="row">
          <LibraryFilter onChange={this.handleChangeLibrary} />
          <YearFilter onChange={this.handleChangeYear} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ marginTop: 35, marginBottom: 35 }}>
          <AverageCard year={this.state.year} library={this.state.library} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{height: 300, marginTop: 35}}>
          <Typography variant="h5">Medía de usuarios por día</Typography>
          <UsersAverageByDayMonth year={this.state.year} library={this.state.library} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{height: 300, marginTop: 35}}>
          <Typography variant="h5">Nº de usuario por nº de préstamos</Typography>
          <NUsersByNLoans year={this.state.year} library={this.state.library} />
        </Grid>
        
      </Grid>
    );
  }
}

export default UserStatistics;