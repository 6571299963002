import React, { Component } from "react";

// own component
import LineBarChart from "../Common/ELineChart/LineBar";

// constant
import { COLOR_1 } from "../../constants";

// api
import { getUsersLaons } from "../../api/week";

class UsersLoansInvestor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      lines: [{key: 'loans', color: COLOR_1[3]}],
      bars: [ 
        {key: 'register_user', color: COLOR_1[9]},
        {key: 'active_user', color: COLOR_1[12]},
      ]
    };
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount(){
    this.getStatistics()
  }

serializerData(data){
  var serializer_data = data.map(row => ({
      register_user: row.register_user * 4,
      loans: row.loans * 4,
      name: row.name,
      active_user: row.active_user * 4 + 1
  }))
  this.setState( {
    data: serializer_data
  })
}

  async getStatistics(){
    const statistics = await getUsersLaons()
    if(statistics){
      const { data, status } = statistics
      if(status === 200){
        this.serializerData(data)
      }
    }
  }

  render() {
    return (
      <LineBarChart key={446} data={this.state.data} bars={this.state.bars} lines={this.state.lines} />
    );
  }
}

export default UsersLoansInvestor;