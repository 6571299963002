import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

// own components
import LibraryComponent from "../components/Library/LibraryComponent";

const styles = theme => ({
  root: {
    padding: 20
  }
})

class LibraryView extends Component {
  constructor(props) {
    super(props);
    this.state = {  };
  }
  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h4">Datos por biblioteca</Typography>
          <LibraryComponent />
        </Grid>
      </Grid>    
    );
  }
}

export default withStyles(styles)(LibraryView);