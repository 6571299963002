import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// Own components
import LibraryFilter from "../Common/EFilter/librarys";
import YearFilter from "../Common/EFilter/years";
import SimpleBar from "../Common/EBarChart/SimpleBar";
import LabelLineChart from "../Common/ELineChart/LabelLine";
import StatisticsLoanCards from "./StatisticsLoanCards";
import LoanTwoLastWeeks from "../Loan/LoanTwoLastWeeks";
import LoansCurrentWeekByLibrary from "../Loan/LoansCurrentWeekByLibrary";
import Loans365Days from './Loans365Days';
import Expensive365Days from './Expensive365Days';

// api
import { getAverageLoanByMonth, getLoanByMonth, getLoanByLibrary } from "../../api/loan";

class StatisticsLoan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      year: "",
      library: "",
      averageLoanByMonth: [],
      loansByMonth: [],
      loansByLibrary: []
    }
    this.getStatisticsAverageLoanByMonth = this.getStatisticsAverageLoanByMonth.bind(this)
    this.getStatisticsLoanByMonth = this.getStatisticsLoanByMonth.bind(this)
    this.getStatisticsLoanByLibrary = this.getStatisticsLoanByLibrary.bind(this)
  }

  componentDidMount() {
    this.getStatisticsAverageLoanByMonth()
    this.getStatisticsLoanByMonth()
    this.getStatisticsLoanByLibrary()
  }

  componentDidUpdate(nextProp, prevState) {
    if (prevState.library !== this.state.library || prevState.year !== this.state.year) {
      this.getStatisticsAverageLoanByMonth()
      this.getStatisticsLoanByMonth()
    }
    if (prevState.year !== this.state.year) {
      this.getStatisticsLoanByLibrary()
    }
  }

  serializerAverageLoanByMonth(results) {
    const data = results.map(row => ({
      name: row.month,
      media: row.average
    }))
    return data
  }

  serializerLoanByMonth(results) {
    const data = results.map(row => ({
      name: row.month,
      prestamos: row.cont
    }))
    return data
  }

  serializerLoanByLibrary(results) {
    const data = results.map(row => ({
      name: row.library,
      prestamos: row.num
    }))
    return data
  }

  getQuery() {
    var query = "year=" + this.state.year + "&library=" + this.state.library
    return query
  }

  async getStatisticsLoanByMonth() {
    const statistics = await getLoanByMonth(this.getQuery())
    if (statistics) {
      const { data, status } = statistics
      if (status === 200) {
        this.setState({
          loansByMonth: this.serializerLoanByMonth(data)
        })
      }
    }
  }

  async getStatisticsAverageLoanByMonth() {
    const statistics = await getAverageLoanByMonth(this.getQuery())
    if (statistics) {
      const { data, status } = statistics
      if (status === 200) {
        this.setState({
          averageLoanByMonth: this.serializerAverageLoanByMonth(data)
        })
      }
    }
  }

  async getStatisticsLoanByLibrary() {
    const statistics = await getLoanByLibrary(this.getQuery())
    if (statistics) {
      const { data, status } = statistics
      if (status === 200) {
        this.setState({
          loansByLibrary: this.serializerLoanByLibrary(data)
        })
      }
    }
  }

  handleChangeLibrary = (value) => {
    this.setState({
      library: value
    })
  }

  handleChangeYear = (value) => {
    this.setState({
      year: value
    })
  }

  render() {
    return (
      <Grid container direction="row">
        <Typography variant="h5">Préstamos</Typography>
        <Grid item xs={12} md={12} lg={12} >
          <StatisticsLoanCards />
        </Grid>
        <Grid item xs={12}>          
          <Loans365Days />
        </Grid>
        <Grid item xs={12}>          
          <Expensive365Days />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <LoanTwoLastWeeks library="" multi={1} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <LoansCurrentWeekByLibrary  />
        </Grid>
        <Grid container direction="row">
          <Grid item xs={12} md={3} lg={3}>
            <LibraryFilter onChange={this.handleChangeLibrary} />
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <YearFilter onChange={this.handleChangeYear} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{ height: 300, marginTop: 45 }}>
          <Typography variant="h5">Media diaría</Typography>
          <SimpleBar
            data={this.state.averageLoanByMonth}
            bars={[{ key: 'media', color: '#82ca9d' }]} />
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{ height: 300, marginTop: 45 }}>
          <Typography variant="h5">Prestamos por mes</Typography>
          <SimpleBar
            data={this.state.loansByMonth}
            bars={[{ key: 'prestamos', color: '#8884d8' }]} />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 300, marginTop: 45 }}>
          <Typography variant="h5">Préstamos por biblioteca</Typography>
          <LabelLineChart
            data={this.state.loansByLibrary}
            lines={[{ key: 'prestamos', color: '#82ca9d' }]} />
        </Grid>

      </Grid>
    )
  }
}

export default StatisticsLoan