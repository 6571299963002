import React, { Component } from "react";

// own component
import LineBarChart from "../Common/ELineChart/LineBar";

// constant
import { COLOR_1 } from "../../constants";

// api
import { getUsersLaons } from "../../api/week";

class UsersLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      lines: [{key: 'loans', color: COLOR_1[2]}],
      bars: [ 
        {key: 'register_user', color: COLOR_1[9]},
        {key: 'active_user', color: COLOR_1[12]},
      ]
    };
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount(){
    this.getStatistics()
  }

  async getStatistics(){
    const statistics = await getUsersLaons()
    if(statistics){
      const { data, status } = statistics
      if(status === 200){
        this.setState({
          data: data
        })
      }
    }
  }

  render() {
    return (
      <LineBarChart data={this.state.data} bars={this.state.bars} lines={this.state.lines} />
    );
  }
}

export default UsersLoans;