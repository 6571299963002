import api from "./api";

export async function getEndpoint(URL) {
    try {
      const { status, data } = await api.get(URL)
      if (status === 200){
        const response = {status: status, data: data}
        return response
      }
    } catch (error) {
      const response = {status: error.request.status}
      return response
    }
  }