import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// own compponents
import SimpleBar from "../Common/EBarChart/SimpleBar";
import Pie from "../Common/EPieChart/EPie";
import LabelLine from "../Common/ELineChart/LabelLine";

// api 
import { getStatisticsSuppliers } from "../../api/supplier";

class GeneralStatisticsSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      general_statistics: [],
      loans_movies_statistic: [],
      percentageOfLoans: [],
      expenditures: []
    };
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount() {
    this.getStatistics()
  }

  dataSerializerLoans(results) {
    const data = results.map(row => ({
      name: row.nombreProveedor,
      prestamos: row.loans,
      audiovisuales: row.naudiovisual
    }))
    return data
  }

  dataSerializerPercentageLoans(results){
    const data = results.map(row => ({
      name: row.nombreProveedor,
      value: row.percentage_of_loans
    }))
    return data
  }

  dataSerializerExpenditures(results){
    const data = results.map(row => ({
      name: row.nombreProveedor,
      ingresos: row.expenditure
    }))
    return data
  }

  async getStatistics() {
    const statistics = await getStatisticsSuppliers()
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          loans_movies_statistic: this.dataSerializerLoans(data),
          percentageOfLoans: this.dataSerializerPercentageLoans(data),
          expenditures: this.dataSerializerExpenditures(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid direction="row" container>
        <Grid item lg={12} md={12} sm={12} style={{ height: 300 }} >
          <Typography variant="h5">Audiovisuales vs préstamos</Typography>
          <SimpleBar
            data={this.state.loans_movies_statistic}
            bars={[{ key: "prestamos", color: "#8884d8" }, { key: "audiovisuales", color: "#82ca9d" }]}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} style={{ height: 500, paddingTop: 50 }}>
          <Typography variant="h5" style={{marginBottom: 45}}>Porcentaje de préstamos</Typography>
          <Pie
            data={this.state.percentageOfLoans}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} style={{ height: 500, paddingTop: 50 }}>
          <Typography variant="h5" style={{marginBottom: 45}}>Ingresos</Typography>
          <LabelLine 
            data={this.state.expenditures}
            lines={[{key: 'ingresos', color: '#8884d8'}]}
          />
        </Grid>
      </Grid>
    )
  }
}

export default GeneralStatisticsSupplier;