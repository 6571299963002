import { getEndpoint } from "./generalApi";

const URL = '/api/v1/statistics-by-supplier/';

export async function getStatisticsSuppliers() {
  return getEndpoint(URL);
}

export async function porcentages(query){
  return getEndpoint(URL + "porcentage/" + query)
}

export async function porcentage_by_moths(query){
  return getEndpoint(URL + "porcentage_by_moths/" + query)
}

export async function porcentage_by_year(query){
  return getEndpoint(URL + 'porcentage_by_year/' + query)
}

export async function getSuppliers(){
  return getEndpoint('/api/v1/general-statistics/providers/')
}