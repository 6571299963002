import React, { Component } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

class SimpleBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      bars: props.bars
    };
  }

  componentWillReceiveProps(prevProps){
    this.setState({
      data: prevProps.data,
      bars: prevProps.bars
    })
  }
 
  render() {
    return (
      <ResponsiveContainer>
        <BarChart data={this.state.data}
        margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {this.state.bars.map(row => {
          return (
            <Bar dataKey={row.key} key={row.key}  fill={row.color} />
          )
        })}
      </BarChart>
      </ResponsiveContainer>
      
    );
  }
}

export default SimpleBar;