import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';

// own components
import LabelLineChart from '../Common/ELineChart/LabelLine';

// api
import { getLoans365Days } from '../../api/loan';

class Loans365Days extends Component{
  constructor(props) {
    super(props)
    this.state = {
      loans: []
    }
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount(){
    this.getStatistics()
  }

  async getStatistics(){
    const response = await getLoans365Days()
    if(response) {
      const { data, status } = response
      if ( status === 200) {
        this.serializerData(data)        
      }
    }
  }

  serializerData(data){
    const d = data.map(item => (
      {name: item.name, prestamos: item.prestamos * 4}
    ))
    this.setState({
      loans: d
    })
  }

  render () {
    return (
      <Grid container direction="row">
        <Grid item xs={12} md={12} lg={12} style={{ height: 450, marginTop: 35, marginBottom: 35 }}>
          <h5>Préstamos de los últimos 366 días</h5>
          <LabelLineChart data={this.state.loans} lines={[{ key: 'prestamos', color: '#82ca9d' }]} />
        </Grid>
      </Grid>
    ) 
  }
}

export default Loans365Days