import api from "./api";

const URL = "/api/v1/user-statistics/"

async function getEndpoint(url) {
  try {
    const { status, data } = await api.get(url)
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}
export async function getUserByLibrary() {
  return getEndpoint(URL + 'by_library/')  
}

export async function getUsersByMonth(query) {
  return getEndpoint(URL + 'by_month/?' + query)
}

export async function getNUsersByNLoans(query) {
  return getEndpoint(URL + 'n_user_by_n_loans/?' + query)
}

export async function getAverageMonth(query) {
  return getEndpoint(URL + 'average_month/?' + query)
}

export async function getGeneral(query) {
  return getEndpoint(URL + 'general/?' + query)  
}