import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = {
  card: {
    width: '100%'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  cardContent: {
    textAlign: 'center'
  }
}

class SimpleCard extends Component {
  
  render() {
    const { classes, title, icon, value } = this.props
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography color="textPrimary" className={classes.title}>
            {title}
          </Typography>
          {icon}
          <Typography variant="h5" color="textSecondary">
            {value}
          </Typography>
        </CardContent>
      </Card>
    )
  }
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
}

export default withStyles(styles)(SimpleCard)