import api from "./api";

export async function getStatisticsGenres() {
  try {
    const {status, data} = await api.get('/api/v1/statistics-by-genre/')
    if(status === 200){
      const response = { status: status, data: data}
      return response
    }

  } catch (error) {
    const response = {status: error.request.status}
    return response
  }
}