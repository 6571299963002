import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import LoginView from '../views/LoginView';
import LoginLayout from '../layouts/LoginLayout';
import { connect } from "react-redux";
import HomeLayout from '../layouts/HomeLayout';
import HomeInvestorLayout from "../layouts/HomeInvestorLayout";
import HomeView from '../views/HomeView';
import DetailLibraryView from '../views/DetailLibraryView';
import AverageView from "../views/AverageView";
import LoanView from "../views/LoanView";
import UserView from "../views/UserView";
import LibraryView from "../views/LibraryView";
import MonthlyView from '../views/MonthlyView'
import HomeInvestorView from "../views/HomeInvestorView";
import ProviderView from "../views/ProviderView";

const LoginRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      rest.auth ? (
        <Redirect
          to={{
            pathname: '/home',
            state: { from: matchProps.location }
          }}
          />
      ) : (
          <LoginLayout>
            <Component {...matchProps} />
          </LoginLayout>
        )
    )}
    />
  )
}

const HomeRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={matchProps =>
      rest.auth ? (
        rest.investor?(
          <Redirect
            to={{
              pathname: '/summary',
              state: { from: matchProps.location }
            }}
          />
        ): (
          <HomeLayout class="prueba">
          <Component {...matchProps} />
        </HomeLayout>
        )
      ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: matchProps.location }
            }}
          />
        )}
    />
  )
}

const InvestorRoute = ({component: Component, ...rest}) => {
  return (
    <Route { ...rest} render={matchProps =>
        rest.auth ? (
          <HomeInvestorLayout >
            <Component {...matchProps} />
          </HomeInvestorLayout>
        ): (
          <Redirect
          to={{
            pathname: '/summary',
            state: { from: matchProps.location }
          }} />
        )
    } />
  )
}

const MainRoute = (props) => {
  const { isAuth ,  isInvestor} = props
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => (<Redirect to="/home" />)} />
        <LoginRoute auth={isAuth} investor={isInvestor} path="/login" component={LoginView} />
        <HomeRoute auth={isAuth} investor={isInvestor}  path="/home" component={HomeView} />
        <HomeRoute auth={isAuth}  investor={isInvestor} path="/medias" component={AverageView} />
        <HomeRoute auth={isAuth}  investor={isInvestor} path="/prestamos" component={LoanView} />
        <HomeRoute auth={isAuth}  investor={isInvestor} path="/proveedores" component={ProviderView} />
        <HomeRoute auth={isAuth} investor={isInvestor} path="/bibliotecas" component={LibraryView} />
        <HomeRoute auth={isAuth} investor={isInvestor} path="/mensual" component={MonthlyView} />
        <HomeRoute auth={isAuth}  investor={isInvestor} path="/usuarios" component={UserView} />
        <HomeRoute auth={isAuth} investor={isInvestor} path="/biblioteca/:nombre" component={DetailLibraryView} />
        <InvestorRoute auth={isAuth} investor={isInvestor} path="/summary" component={HomeInvestorView} />
      </Switch>
    </Router>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.userReducer.isAuth,
    isInvestor:  state.userReducer.isInvestor
  }
}

export default connect(mapStateToProps, undefined)(MainRoute);