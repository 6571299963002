import React, { Component } from 'react';
import { Grid } from "@material-ui/core";

// own components
import PieChart from "../Common/EPieChart/EPie";

// own api
import { porcentages } from "../../api/supplier";

class LoansByProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      library: props.library,
      year: props.year
    };
  }

  componentDidMount(){
    this.getStatistics()
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.library !== this.state.library || nextProps.year !== this.state.year){
      this.setState({
        library: nextProps.library,
        year: nextProps.year
      }, () => this.getStatistics())
    }
  }

  getQuery(){
    return "?year=" + this.state.year + "&library=" + this.state.library
  }

  async getStatistics(){
    let response = await porcentages(this.getQuery())
    if(response){
      let { status, data } = response
      if( status === 200){
        this.setState({
          data: data
        })
      }
    } 
  }

  render() {
    return (
      <Grid item lg={12} md={12} sm={12} style={{ height: 400, paddingTop: 50 }}>
        <PieChart data={this.state.data} />
      </Grid>    
    );
  }
}

export default LoansByProvider;