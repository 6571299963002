import React, { Component } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

class EPie extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data
    };
  }

  COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  RADIAN = Math.PI / 180;
  
  renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
    const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  componentWillReceiveProps(prevProps){
    this.setState({
      data: prevProps.data
    })    
  }

  render() {
    return (
      <ResponsiveContainer>
        <PieChart onMouseEnter={this.onPieEnter}>
          <Pie
            dataKey="value"
            data={this.state.data}
            cx={200}
            cy={200}
            labelLine={false}
            label={this.renderCustomizedLabel}
            outerRadius={200}
            fill="#8884d8"
          >
            {this.state.data.map((entry, index) => <Cell key={index} fill={this.COLORS[index % this.COLORS.length]} />)}
          </Pie>
          <Tooltip />
        </PieChart>
        </ResponsiveContainer>
    );
  }
}

export default EPie; 