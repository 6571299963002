import api from "./api";

export async function getLibrarys() {
    try {
        const {status, data} = await api.get('/api/v1/bibliotecas/')
        if (status === 200){
            const response = {status: status, data: data}
            return response
        }
    } catch (error) {
        const response = {status: error.request.status}
        return response 
    }    
}

export async function getStatisticsLibrary() {
    try {
        const {status, data} = await api.get('/api/v1/statistics-by-library/')
        if (status === 200){
            const response = {status: status, data: data}
            return response
        }
    } catch (error) {
        const response = {status: error.request.status}
        return response 
    }    
}

export async function getStatisticsByYear(library) {
    try {
        const {status, data} = await api.get('/api/v1/detail-statistics/by_year/?library='+library)
        if (status === 200){
            const response = {status: status, data: data}
            return response
        }
    } catch (error) {
        const response = {status: error.request.status}
        return response 
    }    
}

export async function getStatisticsByMonth(library, year) {
    try {
        const {status, data} = await api.get(`/api/v1/detail-statistics/by_month/${year}/?library=${library}`)
        if (status === 200){
            const response = {status: status, data: data}
            return response
        }
    } catch (error) {
        const response = {status: error.request.status}
        return response 
    }    
}