import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import TrendingUp from "@material-ui/icons/TrendingUp";
import CalemdarIcon from "@material-ui/icons/CalendarViewDay";
import CalendarWeekIcon from "@material-ui/icons/CalendarTodayOutlined";
import CalendarLastWeekIcon from "@material-ui/icons/CalendarTodayRounded";
import { withStyles } from "@material-ui/core/styles";

// own component
import SimpleCard from "../Common/ECard/SimpleCard";

// api
import { getGeneralLoans } from "../../api/loan";

const styles = {
  paddingGird: {
    padding: 5
  },
  icon: {
    fontSize: 50
  }
}

class StatisticsLoanCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
    this.getStatisticsGeneral = this.getStatisticsGeneral.bind(this)
  }

  componentDidMount(){
    this.getStatisticsGeneral()
  }

  serializerData(results){
    const { classes } = this.props
    const data = [
      { label: 'Préstamos ayer', icon: <CalemdarIcon className={classes.icon} />, value: results.yesterday },
      { label: 'Préstamos hoy', icon: <TrendingUp className={classes.icon} />, value: results.today },
      { label: 'Semana anterior', icon: <CalendarWeekIcon className={classes.icon} />, value: results.last_week },
      { label: 'Esta semana', icon: <CalendarLastWeekIcon className={classes.icon} />, value: results.current_week},
      { label: 'Gastos ayer', icon: <CalemdarIcon className={classes.icon} />, value: results.expensive_yesterday },
      { label: 'Gastos hoy', icon: <TrendingUp className={classes.icon} />, value: results.expensive_today },
      { label: 'Gastos semana anterior', icon: <CalendarWeekIcon className={classes.icon} />, value: results.expensive_last_week },
      { label: 'Gastos esta semana', icon: <CalendarLastWeekIcon className={classes.icon} />, value: results.expensive_current_week}
    ]
    return data
  }

  async getStatisticsGeneral(){
    const response = await getGeneralLoans()
    if(response){
      const { status, data } = response
      if(status === 200){
        this.setState({
          data: this.serializerData(data)
        })
      }
    }
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row">
        {
          this.state.data.map((row, index) => (
            <Grid item key={index} lg={3} md={4} sm={6} xs={6} className={classes.paddingGird}>
              <SimpleCard 
              title={row.label}         
              icon={row.icon}
              value={row.value} />
            </Grid>
          ))
        }
      </Grid>
        );
  }
}

export default withStyles(styles)(StatisticsLoanCards);
