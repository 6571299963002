import React, { Component } from "react";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class BiaxialLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      linesLeft: props.linesLeft,
      linesRight: props.linesRight
    }
  }

  componentWillReceiveProps(prevProps) {
    this.setState({
      data: prevProps.data,
      linesLeft: prevProps.linesLeft,
      linesRight: prevProps.linesRight
    })
  }

  render() {
    return (
      <ResponsiveContainer>
        <LineChart width={600} height={300} data={this.state.data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          {
            this.state.linesLeft.map((row, index) => {
              return (
                <Line
                  yAxisId="left"
                  type="monotone"
                  key={index}
                  dataKey={row.key}
                  stroke={row.color}
                  activeDot={{ r: 8 }}
                />
              )
            })
          }
          {
            this.state.linesRight.map((row, index) => {
              return (
                <Line
                  yAxisId="right"
                  type="monotone"
                  key={index}
                  dataKey={row.key}
                  stroke={row.color}
                />
              )
            })
          }

        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default BiaxialLine;