import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// own components
import SimpleBar from "../Common/EBarChart/SimpleBar";
import ActiveShapePie from "../Common/EPieChart/EActiveShapePie";
import LabelLine from "../Common/ELineChart/LabelLine";
import Pie from "../Common/EPieChart/EPie";

// api
import { getStatisticsColors } from "../../api/color";

class GeneralStatisticsColor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loans: [],
      audiovisuals: [],
      expenditures: [],
      percentageOfLoans: []
    };
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount() {
    this.getStatistics()
  }

  dataSerializerLoans(results) {
    const data = results.map(row => ({
      name: row.nombreTipoMostrar,
      prestamos: row.loans
    }))
    return data
  }

  dataSerializerAudiovisuals(results){
    const data = results.map(row => ({
      name: row.nombreTipoMostrar,
      audiovisuales: row.naudiovisual
    }))
    return data
  }

  dataSerializerPercentageLoans(results){
    const data = results.map(row => ({
      name: row.nombreTipoMostrar,
      value: row.percentage_of_loans
    }))
    return data
  }

  dataSerializerExpenditures(results){
    const data = results.map(row => ({
      name: row.nombreTipoMostrar,
      value: row.expenditure
    }))
    return data
  }

  async getStatistics() {
    const statistics = await getStatisticsColors()
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          loans: this.dataSerializerLoans(data),
          audiovisuals: this.dataSerializerAudiovisuals(data),
          percentageOfLoans: this.dataSerializerPercentageLoans(data),
          expenditures: this.dataSerializerExpenditures(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid container direction="row" >
        <Grid item lg={6} md={12} sm={12} style={{ height: 300, paddingTop: 40 }}>
          <Typography variant="h5">Nº de préstamos</Typography>
          <SimpleBar
            data={this.state.loans}
            bars={[{ key: "prestamos", color: "#82ca9d" }]}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} style={{ height: 300, paddingTop: 40 }}>
          <Typography variant="h5">Nº de audiovisuales</Typography>
          <LabelLine
            data={this.state.audiovisuals}
            lines={[{ key: "audiovisuales", color: "#82ca9d" }]}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} style={{ height: 500, paddingTop: 40 }}>
          <Typography variant="h5">Porcentage de préstamos</Typography>
          <Pie
            dataKey="value"
            data={this.state.percentageOfLoans}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} style={{ height: 500, paddingTop: 40 }}>
          <Typography variant="h5">Ingresos</Typography>
          <ActiveShapePie
            dataKey="value"
            data={this.state.expenditures}
          />
        </Grid>
        
      </Grid >
    );
  }
}

export default GeneralStatisticsColor;