import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

// own components
import Table from "../Common/ETable/ETable";

// api 
import { getStatisticsGenres } from "../../api/genre";

class TableGenre extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount(){
    this.getStatistics()
  }

  columnName = [
    { id: 'genre', numeric: false, disablePadding: false, label: 'Género' },
    { id: 'audiovisuals', numeric: true, disablePadding: false, label: 'Nº audiovisuales' },
    { id: 'loans', numeric: true, disablePadding: false, label: 'Nº préstamos' },
    { id: 'perc_loans', numeric: true, disablePadding: false, label: '% préstamos' },
    { id: 'expenditure', numeric: true, disablePadding: false, label: 'Ingresos' }    
  ]

  dataSerializer(results){
    const data = results.map(row => ({
      id: row.pk,
      genre: row.genreName,
      audiovisuals: row.naudiovisual,
      loans: row.loans,
      perc_loans: row.percentage_of_loans + " %",
      expenditure: row.expenditure + " €"
    }))
    return data
  }

  async getStatistics(){
    const statistics = await getStatisticsGenres()
    if(statistics){
      const { status , data } = statistics
      if (status === 200){
        this.setState({
          data: this.dataSerializer(data)
        })
      }
    }
  }

  render(){
    return (
      <Grid container direction="column">
        <Table 
          columnName={this.columnName}
          data={this.state.data}
          orderBy="genre"
        />
      </Grid>
    )
  }
}

export default TableGenre;