//load base url according to the environment
export const BASE_URL = process.env.REACT_APP_API_URL

//redux
export const AUTHENTICATED = 'AUTHENTICATED'
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
export const IS_INVESTOR = 'IS_INVESTOR'
export const NOT_IS_INVESTOR = 'NOT_IS_INVESTOR'

//status tickets
export const NEW_TICKETS = 4
export const PENDING_TICKETS = 3
export const CLOSED_TICKETS = 2
export const OPEN_TICKETS = 1

//status drawer
export const OPEN_DRAWER = 'OPEN_DRAWER'
export const NOT_OPEN_DRAWER = 'NOT_OPEN_DRAWER'

//status search bar
export const WRITE_SEARCH = 'WRITE_SEARCH'

export const WEEK_DAY = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miercoles',
  'Jueves',
  'Viernes',
  'Sábado'
]

export const COLOR_1 = [
  '#008080',
  '#66CDAA',
  '#4682B4',
  '#7B68EE',
  '#A52A2A',
  '#B22222',
  '#CD5C5C',
  '#C71585',
  '#FF8C00',
  '#BDB76B',
  '#4B0082',
  '#32CD32',
  '#006400',
  '#0000FF',
  '#BC8F8F',
  '#778899',
  '#2F4F4F',
  '#9ACD32' 
]