import React, { Component } from "react";

// own components 
import ESelect from "../../Common/ESelect/ESelect";

// api 
import { getMonths } from "../../../api//month";

class Monthly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: null,
      options: []
    };
    this.getMonthList = this.getMonthList.bind(this)
  }

  componentDidMount(){
    this.getMonthList()
  }

  serializerYears(months){
    const data = months.map(row => ({
      value: row.month,
      text: row.month
    }))
    // data.push({
    //   value: "12-months",
    //   text: "Últimos 12 meses"
    // })
    return data
  }

  async getMonthList(year, library){
    let query = ""
    if(year && library){
      query = `?library=${library}&year=${year}`
    }
    const response = await getMonths(query)
    if (response){
      const { status, data } = response
      if (status === 200 ){
        this.setState({
          options: this.serializerYears(data)
        })
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.year !== this.props.year){
      this.getMonthList(nextProps.year, nextProps.library)
    }
  }

  handleChangeSelect = (value) => {
    this.props.onChange(value)
  }

  render() {
    return (
      <ESelect 
      options={this.state.options}
      name="Mes"         
      id="select-month"
      onChange={this.handleChangeSelect} />
    );
  }
}

export default Monthly;