import React, { Component } from "react";
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class LineBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      lines: props.lines,
      bars: props.bars
    };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data !== this.state.data ||  nextProps.lines !== this.state.lines || nextProps.bars !== this.state.bars){
      this.setState({
        data: nextProps.data,
        lines: nextProps.lines,
        bars: nextProps.bars
      })
    }
  }

  render() {
    return (
      <ResponsiveContainer>
        <ComposedChart width={600} height={400} data={this.state.data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          
          {
            this.state.bars.map((row, index) => {
              return (
                <Bar dataKey={row.key} 
                barSize={20} 
                fill={row.color}
                key={"a"+index} />
              )
            })
          }
          {
            this.state.lines.map((row, index) => {
              return (
                <Line 
                key={index}
                type='monotone' 
                dataKey={row.key} 
                stroke={row.color} />
              )
            })
          }
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default LineBar;

