import React, { Component } from "react";

// own components
import Table from "../Common/ETable/ETable";

// api 
import { getGeneral } from "../../api/year";

class YearGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columnName: [
        { id: 'year', numeric: true, disablePadding: false, label: 'Año' },
        { id: 'loans', numeric: true, disablePadding: false, label: 'Nº préstamos' },
        { id: 'average_loans', numeric: true, disablePadding: false, label: 'M. Prest. día' },
        { id: 'active_user', numeric: true, disablePadding: false, label: 'Usuarios activos' },
      ],
      data: [],
      multi: props.multi
    };
    this.getData = this.getData.bind(this)
  }

  componentDidMount(){
    this.getData()
  }

serializerData(data){
  var serializer_data = data.map(row => ({
    average_loans: row.average_loans * this.state.multi,
    active_user: row.active_user * this.state.multi,
    id: row.id,
    loans: row.loans * this.state.multi,
    year: row.year
  }))
  this.setState({
    data: serializer_data
  })
}

  async getData(){
    const statistics = await getGeneral()
    if(statistics){
      const { data, status } = statistics
      if( status === 200){
        this.serializerData(data)
      }
    }
  }

  render() {
    return (
      <Table 
      data={this.state.data}
      columnName={this.state.columnName}
      titleTable="Estadisticas por año"
      orderBy="year"
      />
    );
  }
}

export default YearGeneral;