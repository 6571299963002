import React, { Component } from "react";

// own components
import SimpleBarChart from "../Common/EBarChart/SimpleBar";

// constants 
import { COLOR_1 } from "../../constants";

// api 
import { getAverageMonth } from "../../api/user";

class UsersAverageByDayMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: props.year,
      library: props.library
    };
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.year !== this.state.year || nextProps.library !== this.state.library){
      this.setState({
        year: nextProps.year,
        library: nextProps.library
      }, () => this.getStatistics())
    }
  }

  componentDidMount(){
    this.getStatistics()
  }

  getQuery(){
    return 'year=' + this.state.year + '&library=' + this.state.library
  }

  async getStatistics(){
    const statistics = await getAverageMonth(this.getQuery())
    if(statistics){
      const { data, status } = statistics
      if(status === 200){
        this.setState({
          data: data
        })
      }
    }
  }

  render() {
    return (
      <SimpleBarChart data={this.state.data} bars={[{key: 'media', color: COLOR_1[2]}]} />
    );
  }
}

export default UsersAverageByDayMonth;