import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

// own component
import LabelLine from "../Common/ELineChart/LabelLine";

// constants 
import { WEEK_DAY } from "../../constants";

// api
import { getLoansTwoLastWeeks } from "../../api/loan";

const styles = {

}

class LoanTwoLastWeeks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      two_last_weeks: [],
      multi: props.multi,
      library: props.library
    };
    this.getStatisticsTwoLastWeeks = this.getStatisticsTwoLastWeeks.bind(this)
  }

  componentDidMount() {
    this.getStatisticsTwoLastWeeks()
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.library !== this.props.library){
      this.setState({
        library: nextProps.library
      }, () => this.getStatisticsTwoLastWeeks())
    }

  }

  getQuery(){
    var query = "library=" + this.state.library
    return query
  }

  serializerData(results) {
    const data = results.current_week.map((row, index) => ({
      name: WEEK_DAY[(parseInt(row.weekday) - 1)],
      actual: row.cont * this.state.multi - 1,
      anterior: results.last_week[index].cont * this.state.multi - 1
    }))
    return data
  }

  async getStatisticsTwoLastWeeks() {
    const statistics = await getLoansTwoLastWeeks(this.getQuery())
    if (statistics) {
      const { data, status } = statistics
      if (status === 200) {
        this.setState({
          two_last_weeks: this.serializerData(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid container direction="row">
        <Grid item xs={12} md={12} lg={12} style={{ height: 450, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Préstamos dos últimas semanas</Typography>
          <LabelLine data={this.state.two_last_weeks} lines={[{ key: 'actual', color: '#82ca9d' }, { key: 'anterior', color: '#ff0000' }]} />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(LoanTwoLastWeeks);