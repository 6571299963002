import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

// own components
import UserComponent from "../components/User/UserStatistics";

const styles = theme => ({
  root: {
    padding: 20
  }
})

class UserView extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row" className={classes.root} >
        <UserComponent />
      </Grid>
    );
  }
}
 
export default withStyles(styles)(UserView);