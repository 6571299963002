import api from "./api";

const URL = "/api/v1/loan-statistics/"

async function getEndpoint(url) {
  try {
    const { status, data } = await api.get(url)
    if (status === 200) {
      const response = { status: status, data: data }
      return response
    }
  } catch (error) {
    const response = { status: error.request.status }
    return response
  }
}

export async function getMonths(query) {
  return getEndpoint(URL + 'months/'+ query )
}