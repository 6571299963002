import React, { Component } from "react";

// own components
import ESelect from "../../Common/ESelect/ESelect";

//api
import { getLibrarys } from "../../../api/library";

class librarys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
    this.librarys = this.librarys.bind(this)
  }

  componentDidMount(){
    this.librarys()
  }

  serializerLibrarys(librarys){
    const data = librarys.map(row => ({
      value: row.nombreBiblioteca,
      text: row.nombreBiblioteca
    }))
    return data
  }
  
  async librarys(){
    const response = await getLibrarys()
    if(response){
      const { data, status } = response
      if( status === 200 ){
        this.setState({
          options: this.serializerLibrarys(data.results)
        })
      }
    }
  }

  handleChangeSelect = (value) => {
    this.props.onChange(value)
  }

  render() {
    return (
      <ESelect 
      onChange={this.handleChangeSelect}
      options={this.state.options}
      name="Biblioteca"
      id="select-library"
      />
    );
  }
}

export default librarys;