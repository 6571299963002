import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

// api
import { getLoansByAge } from "../../api/age";

// own components 
import LabelLineChart from "../Common/ELineChart/LabelLine";

class AgeChart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
    this.getStatistics = this.getStatistics.bind(this)
  }

  componentDidMount() {
    this.getStatistics()
  }

  dataSerializer(results) {
    const data = results.map(row => ({
      name: row.age,
      prestamos: row.num
    }))
    return data
  }

  async getStatistics() {
    const statistics = await getLoansByAge()
    if (statistics) {
      const { status, data } = statistics
      if (status === 200) {
        this.setState({
          data: this.dataSerializer(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid container direction="row">
        <Grid item lg={12} md={12} sm={12} style={{ height: 300, paddingTop: 40 }}>
          <LabelLineChart
            data={this.state.data}
            lines={[{ key: "prestamos", color: "#82ca9d" }]}
          />
        </Grid>
      </Grid>
    )
  }
}

export default AgeChart;