import React, { Component } from "react";

// own components
import ESelect from "../../Common/ESelect/ESelect";

//api
import { getSuppliers } from "../../../api/supplier";

class Providers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
    this.providers = this.providers.bind(this)
  }

  componentDidMount(){
    this.providers()
  }

  serializerProviders(providers){
    const data = providers.map(row => ({
      value: row.id,
      text: row.nombreProveedor
    }))
    return data
  }
  
  async providers(){
    const response = await getSuppliers()
    if(response){
      const { data, status } = response
      if( status === 200 ){
        this.setState({
          options: this.serializerProviders(data)
        })
      }
    }
  }

  handleChangeSelect = (value) => {
    this.props.onChange(value)
  }

  render() {
    return (
      <ESelect 
      onChange={this.handleChangeSelect}
      options={this.state.options}
      name="Provedores"
      id="select-provider"
      />
    );
  }
}

export default Providers;