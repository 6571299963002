import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import FilterLibrary from "../components/Common/EFilter/librarys";
import FilterYear from "../components/Common/EFilter/years";
import FilterSupplier from "../components/Common/EFilter/providers"
import FilterMonthly from '../components/Common/EFilter/monthly'
import LabelLineChart from '../components/Common/ELineChart/LabelLine'
import { getLoansByDay, getExpensesByDay, getUsersRegisterByDay } from '../api/loan'

// own components
const styles = theme => ({
  root: {
    padding: 20
  }
})

class MonthlyView extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      library: null,
      supplier: null,
      year: null,
      month: null,
      totalLoans: 0,
      totalExpensive: 0,
      totalRegister: 0,
      loansByDay: [],
      expensesDay: [],
      userRegisterDay: []
    };
    this.getDataLoanByDays = this.getDataLoanByDays.bind(this)
    this.getDataExpensesDay = this.getDataExpensesDay.bind(this)
    this.getDataUserRegisterByDay = this.getDataUserRegisterByDay.bind(this)
  }

  componentDidMount(){
    this.getDataLoanByDays()
    this.getDataExpensesDay()
    this.getDataUserRegisterByDay()
  }

  async getDataLoanByDays(){
    try {
      const response = await getLoansByDay(this.getQuery())
      if (response){
        const { status, data } = response
        if (status === 200) {
          const serializerData = data.map((item, index) => {
            this.setState({
              totalLoans: this.state.totalLoans + item.prestamos
            })
            return {prestamos: item.prestamos, name: item.day}
          })
          this.setState({loansByDay: serializerData})
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getDataExpensesDay() {
    try {
      const response = await getExpensesByDay(this.getQuery())
      if (response){
        const { status, data } = response
        if (status === 200) {
          const serializerData = data.map((item, index) => {
            this.setState({
              totalExpensive: this.state.totalExpensive + item.total
            })
            return {total: item.total, name: item.day}
          })
          this.setState({expensesDay: serializerData})
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  async getDataUserRegisterByDay() {
    try {
      const response = await getUsersRegisterByDay(this.getQuery())
      if (response){
        const { status, data } = response
        if (status === 200) {
          const serializerData = data.map((item, index) => {
            this.setState({
              totalRegister: this.state.totalRegister + item.registers
            })
            return {registers: item.registers, name: item.day}
          })
          this.setState({userRegisterDay: serializerData})
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  getQuery = () => (
    { 
      library: this.state.library,
      provider: this.state.supplier,
      year: this.state.year,
      month: this.state.month
    }
  )
  
  handleLibrary = (value) => this.setState({library: value}, () => {
    this.setState({
      totalExpensive: 0,
      totalRegister: 0,
      totalLoans: 0
    })
    this.getDataLoanByDays()
    this.getDataExpensesDay()
    this.getDataUserRegisterByDay()
  })
  
  handleSupplier = (value) => this.setState({supplier: value}, () => {
    this.setState({
      totalExpensive: 0,
      totalRegister: 0,
      totalLoans: 0
    })
    this.getDataLoanByDays()
    this.getDataExpensesDay()
    this.getDataUserRegisterByDay()
  })
  
  handleYear = (value) => this.setState({ year: value }, () => {
    this.setState({
      totalExpensive: 0,
      totalRegister: 0,
      totalLoans: 0
    })
    this.getDataLoanByDays()
    this.getDataExpensesDay()
    this.getDataUserRegisterByDay()
  })

  handleMonth = (value) => this.setState({ month: value }, () => {
    this.setState({
      totalExpensive: 0,
      totalRegister: 0,
      totalLoans: 0
    })
    this.getDataLoanByDays()
    this.getDataExpensesDay()
    this.getDataUserRegisterByDay()
  })

  render() {
    const { classes } = this.props
    return (
      <Grid container direction="row" className={classes.root}>
        <Grid item xs={12}>
          <Typography variant="h4">Prestamos por mes</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <FilterLibrary onChange={this.handleLibrary} />
        </Grid>
        <Grid item xs={12} md={3}>
          <FilterSupplier onChange={this.handleSupplier} />
        </Grid>
        <Grid item xs={12} md={3}>
          <FilterYear onChange={this.handleYear} library={this.state.library}/>
        </Grid>
        <Grid item xs={12} md={3}>
          <FilterMonthly onChange={this.handleMonth} library={this.state.library} year={this.state.year}/>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 400, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Préstamos por día - Total: {this.state.totalLoans}</Typography>
          <LabelLineChart data={this.state.loansByDay} lines={[{ key: 'prestamos', color: '#82ca9d' }]}/>
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ height: 400, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Gastos por día - Total: {this.state.totalExpensive}</Typography>
          <LabelLineChart data={this.state.expensesDay} lines={[{ key: 'total', color: '#82ca9d' }]}/>
        </Grid> 
        <Grid item xs={12} md={12} lg={12} style={{ height: 400, marginTop: 35, marginBottom: 35 }}>
          <Typography variant="h5">Registros por día - Total: {this.state.totalRegister} </Typography>
          <LabelLineChart data={this.state.userRegisterDay} lines={[{ key: 'registers', color: '#82ca9d' }]}/>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(MonthlyView);