import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";

// own components
import SimpleBarChart from "../Common/EBarChart/SimpleBar";

// api
import { getUserByLibrary } from "../../api/user";

class UserByLibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      multi: props.multi,
      data: []
    };
    this.getStatisticsByLibrary = this.getStatisticsByLibrary.bind(this)
  }

  componentDidMount(){
    this.getStatisticsByLibrary()
  }

  serializerData(results){
    const data = results.map(row => ({
      name: row.biblioteca__nombreBiblioteca,
      usuarios: row.cont * this.state.multi 
    }))
    return data
  }

  async getStatisticsByLibrary(){
    const statistics = await getUserByLibrary()
    if(statistics){
      const {data, status} = statistics
      if(status === 200){
        this.setState({
          data: this.serializerData(data)
        })
      }
    }
  }

  render() {
    return (
      <Grid item xs={12} md={12} lg={12} style={{ height: 300, marginTop: 35, marginBottom: 35 }}>
        <SimpleBarChart data={this.state.data} bars={[{key: 'usuarios', color: '#8b4c8c'}]} />
      </Grid>    
    );
  }
}

export default UserByLibrary;