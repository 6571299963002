import { OPEN_DRAWER , NOT_OPEN_DRAWER} from '../constants' 


const initialState = {
    isOpenDrawer: false,
}

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_DRAWER:
            return {
                ...state,
                isOpenDrawer: true
            }
        case NOT_OPEN_DRAWER:
            return {
                ...state,
                isOpenDrawer: false
            }
        default:
            return state;
    }
}
export default drawerReducer;