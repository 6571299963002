import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import HomeIcon from "@material-ui/icons/Home";
import LibraryIcon from "@material-ui/icons/LocalLibrary";
import { withRouter } from 'react-router'
import { connect } from "react-redux";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getLibrarys } from "../../../api/library";

const drawerWidth = 240;

const styles = theme => ({
  rootDrawer: {
    display: 'flex',
  },
  zIndexDesktop: {
    display: 'flex',
    zIndex: 1,
  },
  zIndexMovil: {
    display: 'flex',
    zIndex: 1000,
  },
  icon: {
    margin: theme.spacing.unit * 2,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    zIndex: 20000
  },
  toolbar: theme.mixins.toolbar,
});

class EDrawer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      'menu': [
        {'item': 'Home', 'icon': <HomeIcon /> }        
      ],
      bibliotecas: []
    };
    this.handleClick = this.handleClick.bind(this)
    this.getBibliotecas = this.getBibliotecas.bind(this)
  }

  componentDidMount() {
    this.getBibliotecas()    
  }

  handleClick (event) {
    var name = event.target.innerText
    if (name === 'Biblioteca') {
      this.setState(state => ({ open: !state.open }));
    }
    else {
      const url = '/'+name.toLowerCase()
      this.props.history.push(url)
    }
  }

  async getBibliotecas() {
    const librarys = await getLibrarys()
    if (librarys) {
      const { status, data } = librarys
      if (status === 200) {
        this.setState({
          bibliotecas: this.serializarBibliotecas(data.results)
        })
      }
    }
  }

  serializarBibliotecas(results) {
    const data = results.map(row => ({
      id: row.id,
      name: row.nombreBiblioteca
    }))
    return data
  }

  renderDrawer = (isHandledByOpenDrawer) => {
    const { classes, history, isOpenDrawer } = this.props;
    return (
      <Drawer
        className={classes.drawer}
        variant="persistent"
        open={isHandledByOpenDrawer ? isOpenDrawer : true}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List
          component="nav"
          className={classes.root}
        >
          <ListItem button onClick={this.handleClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText inset primary="Home" />
          </ListItem>
          <ListItem button onClick={this.handleClick}>
            <ListItemText inset primary="Prestamos" />
          </ListItem>
          <ListItem button onClick={this.handleClick}>
            <ListItemText inset primary="Proveedores" />
          </ListItem>
          <ListItem button onClick={this.handleClick}>
            <ListItemText inset primary="Usuarios" />
          </ListItem> 
          <ListItem button onClick={this.handleClick}>
            <ListItemText inset primary="Bibliotecas" />
          </ListItem>
          <ListItem button onClick={this.handleClick}>
            <ListItemText inset primary="Mensual" />
          </ListItem>          
          <ListItem button onClick={this.handleClick}>
            <ListItemIcon>
              <LibraryIcon />
            </ListItemIcon>
            <ListItemText inset primary="Biblioteca" />
            {this.state.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {this.state.bibliotecas.map((item, index) => {
                const url = '/biblioteca/' + item.name
                return (
                  <ListItem button className={classes.nested} key={item.name} onClick={() => history.push(url)}>
                    <ListItemText inset primary={item.name} />
                  </ListItem>
                )
              })
              }

            </List>
          </Collapse>
        </List>
      </Drawer>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.rootDrawer}>
        <CssBaseline />
        <Hidden mdUp>
          {this.renderDrawer(true)}
        </Hidden>
        <Hidden smDown>
          {this.renderDrawer(false)}
        </Hidden>
      </div>
    );
  }
}

EDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isOpenDrawer: state.drawerReducer.isOpenDrawer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onOpenDrawer: () => dispatch({ type: 'OPEN_DRAWER' }),
    onCloseDrawer: () => dispatch({ type: 'NOT_OPEN_DRAWER' }),
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EDrawer)));